import { createSlice,PayloadAction } from "@reduxjs/toolkit";
import {setItem} from "../../utils/token"
import { startTestApi } from "../API/StartTest/startTestApi";


interface Answer {
    isActive: boolean;
    answerOption: string;
    isCorrectAnswer: boolean;
    correctAnswer: string;
    answerPlaceHolder: string;
  
}

interface ReadingModuleData{
    _id: string;
    isActive: boolean;
    description: string;
    question: string;
    processedQuestion:string;
    explanation: string;
    moduleId: string;
    sectionId: string;
    answers: Answer[];
    questionTypeId: string;
    more: any; // You might want to replace 'any' with a more specific type
    additionalProps: any; 
}
// Define the specific keys you want to allow in the payload
type SpentTimeFields = 'readingAndWritingModuleOneSpentTime' | 
                      'readingAndWritingModuleTwoSpentTime' | 
                      'mathModuleOneSpentTime' | 
                      'mathModuleTwoSpentTime';

// Define the action payload type
interface SetSpentTimePayload {
  field: SpentTimeFields;
  value: number;
}

interface TestData{
testid:string;
userTestId:string;
name:string;
isFreeTest:boolean;
testType:string;
isTestStarted:boolean
}

interface FullTestState{
    isDummy:boolean
    currentTime:number,
    currentModuleName:string
    readingModuleOneQuestions:ReadingModuleData[], 
    readingModuleTwoQuestions:ReadingModuleData[], // Question
    mathModuleOneQuestions:ReadingModuleData[] ,
    mathModuleTwoQuestions:ReadingModuleData[],
    count:number,
    isPopUp:boolean,
    testData:TestData[],
    testId:string,
    testName:string,
    userTestId:string,
    overallresult:any,
    completedTestData:any,
    currentClickedQuestionId:string,
    isTestCompleted:false,
    readingAndWritingModuleOneSpentTime:number,
    readingAndWritingModuleTwoSpentTime:number,
    mathModuleOneSpentTime:number,
    mathModuleTwoSpentTime:number
    sidebarCollapse:boolean;
}


const initialState:FullTestState ={
    isDummy:true,
    currentTime:1920,
    currentModuleName:"writingModule1",
    testData:[],
    completedTestData:[],
    testId:"",
    userTestId:"",
    testName:"",
    readingModuleOneQuestions:[],
    readingModuleTwoQuestions:[],
    mathModuleOneQuestions:[],
    mathModuleTwoQuestions:[],
    count:0,
    isPopUp:false,
    overallresult:{},
    currentClickedQuestionId:"",
    isTestCompleted:false,
    readingAndWritingModuleOneSpentTime:0,
    readingAndWritingModuleTwoSpentTime:0,
    mathModuleOneSpentTime:0,
    mathModuleTwoSpentTime:0,
    sidebarCollapse:false
}

const startFullTest = createSlice({
    name:"startFullTest",
    initialState,
    reducers:{
        setIsSidebarCollapse:(state,action) => {
            state.sidebarCollapse = action.payload
        },
        isDummyRender:(state,action) => {
            state.isDummy = action.payload
        },
        changeCurrentModuleName:(state,action) => {
            state.currentModuleName = action.payload
            setItem("currentModuleName",action.payload)
        },
        setSpentTime :(state,action:PayloadAction<SetSpentTimePayload>) => {
            const { field, value } = action.payload;
            if (state.hasOwnProperty(field)) {
                state[field] = value;
              }
        },
        setTestId:(state,action) => {
            state.testId= action.payload;
        },
        setTestName:(state,action) => {
            state.testName =action.payload;
        },
        setUserTestId:(state,action) => {
            state.userTestId= action.payload;
        },
        setCurrentClickedQuestion:(state,action) => {
            console.log(action.payload,"action.payload")
            state.currentClickedQuestionId =action.payload
        },
        setReadingModuleOneQuestions:(state,action)=> {
            state.readingModuleOneQuestions = action.payload
        },
        setReadingModuleTwoQuestions:(state,action)=> {
            state.readingModuleTwoQuestions = action.payload
        },
        setMathModuleOneQuestions:(state,action) => {
            state.mathModuleOneQuestions= action.payload;
        },
        setMathModuleTwoQuestions:(state,action) => {
            state.mathModuleTwoQuestions= action.payload;
        },
        setCount:(state,action) => {
            
            if(action.payload === "Increment"){
                state.count++
            }else if(action.payload === "decrement"){
                state.count--
            }else if(action.payload ){
                state.count = action.payload
            }else{
                state.count = 0
            }
          
        },
        setCurrentTime:(state,action) => {
            state.currentTime = action.payload
        },
        setIsPopUp:(state,action) => {
            state.isPopUp =action.payload
        },
       setOverallResultSlice:(state,action) =>{
                state.overallresult = action.payload
       },
       setTestCompleted:(state,action) => {
        state.isTestCompleted = action.payload
       }
    },
    extraReducers: builder => {
        builder.addMatcher(
           startTestApi.endpoints.getAllMockTests.matchFulfilled,
           (state: FullTestState, action: PayloadAction<{ data: { testData: TestData[] } }>) => {
             state.testData = action.payload?.data?.testData;
           }
        );
        builder.addMatcher(
            startTestApi.endpoints.getProTests.matchFulfilled,
            (state: FullTestState, action: PayloadAction<{ data: any }>) => {
                let temp:any= [...state.testData,...action?.payload.data?.testData];
               
                let tests = temp.filter((itm:any,index:any,array:any) => {
                    let matchedIndex = array.findIndex((item:any) => item.testid === itm.testid);
                    return matchedIndex === index;
                })
                console.log(temp,tests,"TEMP");
              state.testData = tests
            }
         );
        builder.addMatcher(
            startTestApi.endpoints.getAllCompletedTest.matchFulfilled,
            (state: FullTestState, action: PayloadAction<{ data: { testData: TestData[] } }>) => {
              state.completedTestData = action.payload?.data;
            }
         );
       }
       
      
})

export const {setIsSidebarCollapse,isDummyRender,changeCurrentModuleName,setSpentTime,setTestId,setCurrentClickedQuestion,setTestCompleted,setUserTestId,setTestName,setReadingModuleOneQuestions,setReadingModuleTwoQuestions,setMathModuleOneQuestions,setMathModuleTwoQuestions,setCount,setCurrentTime,setIsPopUp,setOverallResultSlice} = startFullTest.actions
export default startFullTest.reducer;